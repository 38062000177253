//Fuuka Yamagishi, 2006
import React from "react";
import "./karutaPricing.css";
import { Page } from "../../Components";
import { Button, Checkbox, TextField, styled } from "@material-ui/core";
import johnWayne from "../../noise/johnWayne.mp3";

function calculateEmoji(name, isFirst, price) {
  let calculatedEmoji;

  if (isFirst) calculatedEmoji = "👑️";
  else {
    let randomOdds = Math.floor(Math.random() * 1000);
    if (price === "one handstand") calculatedEmoji = "🤸";
    else {
      if (name === " Fuuka Yamagishi" || randomOdds === 777) {
        calculatedEmoji = "🍋";
      } else calculatedEmoji = "🎟️";
    }
  }

  return calculatedEmoji;
}

// Sorting function that takes array of cards and organizes them by price, tie-breaking by wish number, then edition.
function comparePrice(cardA, cardB) {
  //Sort by price
  if (
    cardA.price < cardB.price ||
    cardB.price === "✫✫✫" ||
    cardB.price === "✫✫" ||
    cardA.price === "No wishes!"
  ) {
    return 1;
  }
  if (
    cardA.price > cardB.price ||
    cardA.price === "✫✫✫" ||
    cardA.price === "✫✫" ||
    cardB.price === "No wishes!"
  ) {
    return -1;
  }
  //Price is the same
  //Sort by wish number instead
  if (cardA.wishes < cardB.wishes) {
    return 1;
  }
  if (cardA.wishes > cardB.wishes) {
    return -1;
  }
  //Wishes are the same too... I bet, buddy
  //Sort by edition
  if (cardA.edition < cardB.edition) {
    return -1;
  }
  if (cardA.edition > cardB.edition) {
    return 1;
  }
  //fuck off
  return 0;
}

//Custom Styling
const EvaluateCardsButton = styled(Button)({
  width: "9rem",
  height: "2.3rem",
  backgroundColor: "#F6E8C7",
  boxShadow: "0 0px 3px 2px rgba(46, 77, 212, .15)",
  textTransform: "none",
  fontWeight: 400,
  color: "#5C5F94",
  "&:hover": {
    backgroundColor: "#F4364C",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#009B77",
  },
  "&:focus": {},
});

const CopyButton = styled(Button)({
  width: "9rem",
  height: "2.3rem",
  backgroundColor: "#d9bdba",
  boxShadow: "0 0px 3px 2px rgba(46, 77, 212, .15)",
  textTransform: "none",
  fontWeight: 400,
  color: "#5C5F94",
  "&:hover": {
    backgroundColor: "#FF6D6A",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#9C081D",
  },
  "&:focus": {},
});

const KarutaPricing = () => {
  const [pricePreset, setPricePreset] = React.useState("jer");
  const [headEyeCatcher, setHeadEyeCatcher] = React.useState("");
  const [footEyeCatcher, setFootEyeCatcher] = React.useState("");
  const [cardsInput, setCardsInput] = React.useState("");
  const [headEyeCatcherBool, setHeadEyeCatcherBool] = React.useState(false);
  const [footEyeCatcherBool, setFootEyeCatcherBool] = React.useState(false);
  const [eyeCatchersSet, setEyeCatchersSet] = React.useState(false);
  const [starSpacers, setStarSpacers] = React.useState(false);
  const [karutaCards, setKarutaCards] = React.useState(["off"]);

  //Rigged up to the price cards button
  function priceCards() {
    new Audio(johnWayne).play();

    if (cardsInput === undefined) {
      setCardsInput("");
    }

    //The alt is for if there is nothing to split
    const cards = cardsInput.split(/\r?\n/);
    //const cards = "";
    let computedCards = [];

    //Price each card
    cards.forEach((card) => {
      try {
        let words = card.split(" ");
        let dotSeparation = card.split("·");
        // eslint-disable-next-line no-unused-vars
        let emoji = dotSeparation[0];

        let prewishes = words[1].split("♡");
        let wishes = prewishes[1];

        let code = dotSeparation[1];
        let stars = dotSeparation[2];

        let prePrintNo = dotSeparation[3].split("#");
        let printNo = prePrintNo[1];

        let preEdition = dotSeparation[4].split("◈");
        let edition = preEdition[1];

        let series = dotSeparation[5];
        let name = dotSeparation[6];

        /*
          ❤️ ♡6210 · 7cgq8f · ★★★★ · #3508  · ◈4 · One Piece · Monkey D. Luffy
          💰 ♡5952 · 79d88v · ★☆☆☆ · #3361  · ◈4 · Genshin Impact · Hu Tao
          ☠️ ♡5196 · d8r32x · ★★☆☆ · #14802 · ◈2 · One Piece · Zoro Roronoa
          ☠️ ♡5196 · ddr0t3 · ☆☆☆☆ · #10016 · ◈3 · One Piece · Zoro Roronoa
          🔥 ♡3435 · ddj1xm · ★☆☆☆ · #3093  · ◈4 · Attack on Titan · Mikasa Ackerman
          ❤️ ♡2491 · 4z281v · ★★★★ · #2656  · ◈3 · Kaguya-sama: Love Is War · Kaguya Shinomiya
          */

        let calculatedPrice = calculatePrice(
          parseInt(wishes),
          parseInt(printNo),
          parseInt(edition),
          name
        );

        let completeCard = {
          wishes: parseInt(wishes),
          code: code,
          stars: stars,
          printNo: parseInt(printNo),
          edition: parseInt(edition),
          series: series,
          name: name,
          price: calculatedPrice,
          calculatedEmoji: "",
          outputRow: "",
        };

        computedCards.push(completeCard);
        console.log(completeCard);
      } catch (error) {
        console.log(
          "Failed processing card: " + card + " with error: " + error
        );
      }
    });

    //Use sorting function on priced deck
    computedCards.sort(comparePrice);

    let isFirst = true;
    computedCards.forEach((computedCard) => {
      computedCard.calculatedEmoji = calculateEmoji(
        computedCard.name,
        isFirst,
        computedCard.price
      );
      //VVVV Will be displayed later
      computedCard.outputRow = `${computedCard.calculatedEmoji} ${computedCard.price} ♡${computedCard.wishes} · ${computedCard.code} · ${computedCard.stars} · #${computedCard.printNo} · ◈${computedCard.edition} · ${computedCard.series} · ${computedCard.name}`;
      if (isFirst) isFirst = false;
    });

    if (computedCards.length > 0) setKarutaCards(computedCards);
    else setKarutaCards(["off"]);
  }

  function calculatePrice(wishes, printNo, edition, name) {
    let price;
    //HIGH PRINT (1000+)— 1 ticket per
    let highEDPricesJer = [1, 500, 450, 350, 225, 200, 180, 160, 140, 120];
    let highEDPricesKCo = [1, 800, 700, 450, 250, 200, 180, 160, 140, 120];
    //MID PRINT (252-999)—
    let midEDPricesJer = [1, 175, 150, 130, 120, 110, 90, 80, 70, 60];
    let midEDPricesKCo = [1, 100, 90, 80, 70, 60, 50, 40, 30, 20];
    //LOW-MID PRINT (101-251)
    let lowEDPricesJer = [1, 120, 100, 70, 60, 55, 50, 35, 25, 15];
    let lowEDPricesKCo = [1, 80, 70, 60, 50, 40, 30, 20, 10, 5];

    //------- Handle priceless cards under number 100  -----------------//
    if (printNo <= 100 && printNo > 10) price = "✫✫";
    else if (printNo <= 10) price = "✫✫✫";
    else if (isNaN(wishes)) price = "No wishes!";
    else {
      //Calculate legitimate price. small chance of handstand
      let handStandOdds = Math.floor(Math.random() * 500);
      if (handStandOdds === 98) {
        price = "one handstand";
      } else {
        if (pricePreset === "jer") {
          price = Math.round(
            wishes /
              (printNo > 1000
                ? highEDPricesJer[edition]
                : printNo <= 1000 && printNo > 251
                ? midEDPricesJer[edition]
                : lowEDPricesJer[edition])
          );
          /*(printNo > 1000)  ? price = Math.round(wishes / highEDPricesJer[edition])
        (printNo <= 1000 && printNo > 251) ? price = Math.round(wishes / midEDPricesJer[edition])
        (printNo <= 251 && printNo > 100)  ? price = Math.round(wishes / lowEDPricesJer[edition])*/
        } else
          price = Math.round(
            wishes /
              (printNo > 1000
                ? highEDPricesKCo[edition]
                : printNo <= 1000 && printNo > 251
                ? midEDPricesKCo[edition]
                : lowEDPricesKCo[edition])
          );
      }
    }
    price = price > 0 ? price : 1;
    return price;
  }

  function copyCards() {
    let copyText = "";
    if (karutaCards) {
      if (headEyeCatcherBool) copyText += headEyeCatcher + "\n";
      karutaCards.forEach((card) => {
        copyText += card?.outputRow + "\n";
        if (
          starSpacers &&
          card?.code !== karutaCards[karutaCards?.length - 1]?.code
        )
          copyText += "✫\n";
      });
      if (footEyeCatcherBool) copyText += footEyeCatcher;
    }
    navigator.clipboard.writeText(copyText);
  }

  function handlePresetChange(newPreset) {
    if (newPreset === "community") alert("Poor choice bro :(");
    setPricePreset(newPreset);
  }

  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth(); //January is 0, dec is 11

  //Run once, and set hte default eye catcher on the page, which is randomly chosen.
  if (!eyeCatchersSet) {
    let defaultEyeCatcher = "";
    if (mm === 9 && dd > 15) {
      //halloweenish time
      defaultEyeCatcher = "🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃";
    } else {
      if (mm === 11) {
        //christmas time
        let christmasRand = Math.floor(Math.random() * 4);
        if (christmasRand === 0)
          defaultEyeCatcher = "🌨️🌨️🌨️🌨️🌨️🌨️🌨️🌨️🌨️🌨️🌨️🌨️🌨️🌨️🌨️🌨️🌨️";
        if (christmasRand === 1)
          defaultEyeCatcher = "☃️☃️☃️☃️☃️☃️☃️☃️☃️☃️☃️☃️☃️☃️☃️☃️☃️";
        if (christmasRand === 2)
          defaultEyeCatcher = "🎅🎅🎅🎅🎅🎅🎅🎅🎅🎅🎅🎅🎅🎅🎅🎅🎅";
        if (christmasRand === 3)
          defaultEyeCatcher = "🎄🎄🎄🎄🎄🎄🎄🎄🎄🎄🎄🎄🎄🎄🎄🎄🎄";
      } else {
        //Non-festive pick
        switch (Math.floor(Math.random() * 5)) {
          case 1:
            defaultEyeCatcher = "🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊";
            break;
          case 2:
            defaultEyeCatcher = "🍥🍥🍥🍥🍥🍥🍥🍥🍥🍥🍥🍥🍥🍥🍥🍥🍥";
            break;
          case 3:
            defaultEyeCatcher = "🐁🐁🐁🐁🐁🐁🐁🐁🐁🐁🐁🐁🐁🐁🐁🐁🐁";
            break;
          case 4:
            defaultEyeCatcher = "🧑‍🦽🧑‍🦽🧑‍🦽🧑‍🦽🧑‍🦽🧑‍🦽🧑‍🦽🧑‍🦽🧑‍🦽🧑‍🦽🧑‍🦽🧑‍🦽🧑‍🦽🧑‍🦽🧑‍🦽🧑‍🦽🧑‍🦽";
            break;
          case 5:
            defaultEyeCatcher = "☁️☁️☁️☁️☁️☁️☁️☁️☁️☁️☁️☁️☁️☁️☁️☁️☁️";
            break;
          default:
            defaultEyeCatcher = "😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️😶‍🌫️";
            break;
        }
      }
    }
    setHeadEyeCatcher(defaultEyeCatcher);
    setFootEyeCatcher(defaultEyeCatcher);
    setEyeCatchersSet(true);
  }

  return (
    <div className="karutaPage">
      <Page>
        <div class="snow_wrap">
          {/* <div class="title">Karuta card pricer</div> */}
          <div class="flexbox">
            <div class="mainInput">
              <div
                className="row"
                style={{
                  width: "",
                }}
              >
                <div style={{ width: "" }} className="header">
                  <div className="headerText">Karuta Card Pricer</div>
                  Copy your karuta card details including their tags and wish
                  counts into the text box, then press{" "}
                  <span
                    onClick={() => priceCards()}
                    style={{ color: "#F02D7D" }}
                  >
                    [Price my cards]
                  </span>
                  .<br />
                  <span className="additionalDescription">
                    Prices marked by stars are low prints, and could go for
                    whatever the seller wants.
                  </span>
                  <br />
                  <span className="additionalDescription">
                    Copy the output for easy advertising in Karuta trading
                    channels.
                  </span>
                </div>
                <div className="shakeBox">
                  <div
                    class="presetChoice"
                    onChange={(event) =>
                      handlePresetChange(event?.target?.value)
                    }
                  >
                    <div className="presetTitle">Price judging preset:</div>

                    <div>
                      <input
                        type="radio"
                        name="priceSets"
                        value="jer"
                        id="jer"
                        defaultChecked
                      />
                      <label for="jer">Mine</label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        name="priceSets"
                        value="community"
                        id="community"
                      />
                      <label for="community">
                        Community determined
                        <div className="disclaimerText">(Not Recommended)</div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "4vh" }}>
                <div className="toggleTextSize" style={{ alignSelf: "center" }}>
                  Space with stars?
                </div>

                <Checkbox
                  labelId="starSpacers"
                  color="default"
                  checked={starSpacers}
                  onChange={() => {
                    if (starSpacers === false) setStarSpacers(true);
                    else setStarSpacers(false);
                  }}
                />
              </div>
              <div style={{ marginBottom: "2.5vh" }}>
                <div className="row">
                  <div
                    className="toggleTextSize"
                    style={{ alignSelf: "center" }}
                  >
                    Eye Catcher on Top?
                  </div>
                  <TextField
                    variant="standard"
                    disableUnderline={false}
                    name="headEyeCatcher"
                    id="headEyeCatcher"
                    class="headEyeCatcher"
                    value={headEyeCatcher}
                    style={{ width: "", marginLeft: "30px" }}
                    onChange={(event) => {
                      setHeadEyeCatcher(event?.target?.value);
                    }}
                  />
                  <Checkbox
                    labelId="headEyeCatcherBool"
                    color="default"
                    checked={headEyeCatcherBool}
                    onChange={() => {
                      if (headEyeCatcherBool === false)
                        setHeadEyeCatcherBool(true);
                      else setHeadEyeCatcherBool(false);
                    }}
                  />
                </div>
              </div>

              <TextField
                name="cardsInput"
                id="cardsInput"
                variant="outlined"
                disableUnderline={true}
                value={cardsInput}
                multiline={true}
                color="info"
                className="joe"
                inputProps={{ style: { color: "#dbf2f1" } }}
                style={{
                  backgroundColor: "#0a2c2a",
                  color: "#dbf2f1",
                  width: "62vw",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
                onChange={(event) => {
                  setCardsInput(event?.target?.value);
                }}
                minRows={6}
                maxRows={10}
                placeholder="Example:
❤️ ♡22 · p205l9 · ★★★★ · #9153 · ◈2 · Neon Genesis Evangelion · Lilith
💛 ♡357 · fdtrk8 · ★★★★ · #6     · ◈4 · Serial Experiments Lain · Lain of the Wired"
              />

              <div className="row">
                <div style={{ width: "100%" }}>
                  <div className="row">
                    <div
                      className="toggleTextSize"
                      style={{ alignSelf: "center" }}
                    >
                      Eye Catcher on Bottom?
                    </div>
                    <TextField
                      type="text"
                      variant="standard"
                      disableUnderline={false}
                      name="footEyeCatcher"
                      id="footEyeCatcher"
                      class="footEyeCatcher"
                      value={footEyeCatcher}
                      style={{ width: "", marginLeft: "5px" }}
                      onChange={(event) => {
                        setFootEyeCatcher(event?.target?.value);
                      }}
                    />
                    <Checkbox
                      labelId="footEyeCatcherBool"
                      color="default"
                      checked={footEyeCatcherBool}
                      onChange={() => {
                        if (footEyeCatcherBool === false)
                          setFootEyeCatcherBool(true);
                        else setFootEyeCatcherBool(false);
                      }}
                    />
                  </div>
                </div>
                <div className="evaluateButton">
                  <EvaluateCardsButton
                    variant="contained"
                    onClick={() => {
                      priceCards();
                    }}
                  >
                    Price my cards
                  </EvaluateCardsButton>
                </div>
              </div>
            </div>
            {karutaCards[0] !== "off" && (
              <div class="below">
                <div className="outputTitle">
                  Output with pricing and formatting
                </div>

                <div className="cardsOutput">
                  {headEyeCatcherBool && <div>{headEyeCatcher}</div>}
                  {karutaCards.map((card) => (
                    <>
                      <div>{card?.outputRow}</div>
                      {starSpacers && // Check to add star
                      card?.code !==
                        karutaCards[karutaCards?.length - 1]?.code ? ( //Weird second long line of conditional is so that if star spacers are on, it won't leave a trailing one after the last card.
                        <div>✫</div>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                  {footEyeCatcherBool && <div>{footEyeCatcher}</div>}
                </div>
                <div className="reverseRow" >
                  <div className="copyButton">
                    <CopyButton
                      variant="contained"
                      onClick={() => {
                        copyCards();
                      }}
                    >
                      Copy cards
                    </CopyButton>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Snow fits at the very bottom of snow_wrap, which is where it's overlaying onto */}
          <div class="snow"></div>
        </div>
      </Page>
    </div>
  );
};

export default KarutaPricing;
