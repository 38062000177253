//Fuuka Yamagishi, 2006
import React from "react";

import { NavLink } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  return (
    <>
      <div className={"nav"}>
        <div className={"navMenu"}>
          <NavLink
            to="/"
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "yellow" : isPending ? "black" : "blue",
              };
            }}
          >
            <img
              src={
                "https://res.cloudinary.com/jerrick/image/upload/v1531856341/p7ykgzou3hjxhvepevcq.jpg"
              }
              alt="dr house"
              width="60vw"
              height="60vh"
            ></img>
          </NavLink>
          <NavLink
            to="/karutaPricing"
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "" : "",
                color: isActive ? "orange" : isPending ? "black" : "white",
                fontSize: "2.5vh",
              };
            }}
          >
            Karuta Pricer
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Navbar;
